import React from "react";
import Resources from "../resources/resources.component";
import { RESOURCE_LINK_ITEMS } from '../resources/resources.utils'

export default function UsefulDocuments() {

  return (
    <>
      <h2><strong>Application</strong></h2>
      <Resources downloadableItems={RESOURCE_LINK_ITEMS.UsefulApp} />
      <h2><strong>Criteria / Policy</strong></h2>
      <Resources downloadableItems={RESOURCE_LINK_ITEMS.UsefulCritPol} />
      <h2><strong>Packaging</strong></h2>
      <Resources downloadableItems={RESOURCE_LINK_ITEMS.UsefulPack} />
    </>
  );
}
