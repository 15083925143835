import React, { } from 'react';
import Resources from './resources.component';
import { RESOURCE_LINK_ITEMS } from './resources.utils'

const ResourcesWrapper = () => {

  return (
    <>
      <section className="splitted-section">
        <div className="splitted-section--half">
          <h2><strong>Customers</strong></h2>
          <Resources downloadableItems={RESOURCE_LINK_ITEMS.Customers} />
        </div>
        <div className="splitted-section--half">
          <h2><strong>Criteria / Policy</strong></h2>
          <Resources downloadableItems={RESOURCE_LINK_ITEMS.CritPol} />
        </div>
        <div className="splitted-section--half">
          <h2><strong>Guides</strong></h2>
          <Resources downloadableItems={RESOURCE_LINK_ITEMS.Guides} />
        </div>
        <div className="splitted-section--half">

          <h2><strong>Income</strong></h2>
          <Resources downloadableItems={RESOURCE_LINK_ITEMS.Income} />

        </div>
        <div className="splitted-section--half">
          <h2><strong>Risk</strong></h2>
          <Resources downloadableItems={RESOURCE_LINK_ITEMS.Risk} />

        </div>
        <div className="splitted-section--half">
          <h2><strong>Specialist lending</strong></h2>
          <Resources downloadableItems={RESOURCE_LINK_ITEMS.SpecLanding} />
        </div>
      </section>
    </>
  );
}
export default ResourcesWrapper;